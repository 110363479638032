<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Pantone.svg" />
        </span>
      </div>
      <div class="alert-text">
        {{this.$route.params.TypeTestName}} at the {{this.$route.params.AgendaName}}
      </div>
    </b-alert>

    <div  class="card shadow-xs cardHover">
      <!-- Tabel -->
      <label ></label>
      <label ></label>
      <div class="form-group">
        <label for="name"></label>
        <b-form-group id="input-group-2"  label-for="input-2" class="mb-2 mr-sm-2 mb-sm-0">
       <b-form-input
         id="input-2"
         v-model="form.name"
         required
         placeholder="Search"
         class="mb-2 mr-sm-2 mb-sm-0"
       ></b-form-input>
     </b-form-group>

      </div>
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      > 
        <b-table
          id="my-table"
          :filter="form.name"
          striped hover :items="items"
          :per-page="perPage"
          :current-page="currentPage"
        >
      </b-table>
      </b-alert>

      <!-- Pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        size="lg"
        class="mt-3"
        align="center"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      selected: null,
      criteria: "", // Properti filter
      fields: ['participant_name', 'score', 'IQ', 'age'],
      items: [],
      perPage: 100,
      currentPage: 1,
      form: {
        name: '',
      },
      show: true
    };
  },
  components: {
  },
  computed: {
     rows() {
       return this.items.length
     }
   },
  methods: {
    filterTable(row, filter) {
      // Ganti row.age_from sesuai dengan kolom yang ingin di-filter
      if (row.age_from.includes(filter)) {
        return true;
      } else {
        return false;
      }
    },

    onSubmit(evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(evt) {
      evt.preventDefault()
      this.form.name = ''
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
  
    getResultCfit() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        Services.GetId(
          ApiService,
          `result/testcfit`,
          this.$route.params.ScheduleID,
          contentType,
          response => {
            // Mengganti items dengan data dari API
            resolve(response.data);
            this.items = response.data.map(item => ({
              participant_name: item.participant_name,
              score: item.score,
              iq: item.iq,
              age: item.age
            }));
            

            // Resolve promise
          },
          err => {
            Swal.fire({
              title: "Data Not Found",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: true,
              timer: 1500
            });
            reject(err);
          }
        );
      });
    },

    loadData() {
      this.getResultCfit()
        .then(() => {
          // Lakukan sesuatu setelah data dimuat
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "schedule", route: "/schedule" },
      { title: "Report Test Cfit" }
    ]);
    this.loadData();
  }
};
</script>
<style>
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form-group label {
  margin-right: 10px;
  flex-grow: 1; /* Optional: Add this to make sure the label takes up the available space on the left */
}
.form-group input {
  flex-grow: 2; /* Optional: Add this to make sure the input field takes up more space on the right */
}
</style>
